import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "../assets/css/bootstrap.css"
import "../assets/css/font-awesome.css"
import "../assets/css/owl.theme.css"
import "../assets/css/animate.css"
import "../assets/css/slick.css"
import "../assets/css/flaticon.css"
import "../assets/css/settings.css"
import "../assets/css/style.css"
import "../assets/css/preset.css"
import "../assets/css/responsive.css"


import MenuWhitePage from "../components/MenuWhitePage/MenuWhitePage"
import SectionTen from "../components/SectionTen/SectionTen"
import Seo from "../components/seo"

// import vid from "../assets/vid/videoBgAbout.mp4"

const AboutUs= () => (
    <>
    <Seo title="About us" />
    <MenuWhitePage/>
    {/* <div className="aboutVid">
        <video autoPlay loop muted id="videoAbout">
            <source src={vid} type="video/mp4"/>
        </video>
    </div> */}
    <section className="aboutusTxt">
        <article>
        <h1>We Understand Our Customers' Needs</h1>
        <p>Rhoni Vision Production is a video and film production company based in Greater Manchester and we believe that every single business has a story that deserves to be told.</p>
        <p>Since 2018, we've specialised in storytelling, video advertising, video marketing, and film production as part of a talented and creative team.</p>
        <p>We have a specialised staff with industry experience and the greatest equipment available to turn your vision into reality and tell your unique story, including promotional videos, explainers, testimonials, animations, social media management, and much more.</p>
        <p>From pre-production, planning, storyboarding, and scriptwriting, to filming yourproject in production, and eventually post-production and distribution, we do it all.</p>
        <button className="aboutusBtn">Ready to get started</button>
        </article>
    </section>

    <section className="aboutusIcons">
        <div className="aboutusIconsDiv">
            <StaticImage src="../images/about/networking.png" alt="" title="" />
            <h2>Influence buying decision</h2>
        </div>
        <div className="aboutusIconsDiv">
            <StaticImage src="../images/about/graphics.png" alt="" title="" />
            <h2>Increase trafic</h2>
        </div>
        <div className="aboutusIconsDiv">
            <StaticImage src="../images/about/web-traffic.png" alt="" title="" />
            <h2>Bump up conversion</h2>
        </div>
        <div className="aboutusIconsDiv">
            <StaticImage src="../images/about/megaphone.png" alt="" title="" />
            <h2>creative brand awerness</h2>
        </div>
    </section>
    <section className="aboutusValues">
        <div>
        <h3> <StaticImage src="../images/about/check.png" alt="" /><span>Increase the brand value</span></h3>
        <h3><StaticImage src="../images/about/check.png" alt="" /><span>Friendly & reliable service</span></h3>
        <h3><StaticImage src="../images/about/check.png" alt="" /><span>Transform Customer engagement</span></h3>
        </div>
    </section>
    <section className="aboutusLeads">
        <h3>Our process leads to RESULTS</h3>
        <article className="aboutusLeadsContainer">
            <section className="aboutusLeadsTxt">
                <div>
                    <h4>Free initial consultation</h4>
                    <p>
                        Rhoni Vision offers a free initial consultation to discuss your requirements, ideas, and goals for video production. After brainstorming, completing a brief, and agreeing on a plan, we then move onto the filming stage of the video production. We have the flexibility to make changes at this stage should circumstances change.
                    </p>
                </div>
                <div>
                    <h4>Keeping you up to date</h4>
                    <p>
                    We will edit all material created during the video production process to ensure you are completely satisfied with the look and feel of our work. We will then produce the video in the formats and definition agreed.
                    </p>
                </div>
                <div>
                    <h4>Growing a rapid pace</h4>
                    <p>
                    The idea of video marketing isn't a new concept; however, it has grown in importance due to the digital world we live in today. Marketing strategies are no longer simple, they are across a range of platforms, technologies, and devices.
                    </p>
                </div>
            </section>
            <section className="aboutusLeadsBox">
                <StaticImage src="../images/about/leads.jpg" alt="" title=""  style={{borderRadius:"5px"}}/>

                <div style={{margin:"0 auto",width:"90%"}}>
                <h4>Rhoni Vision Production</h4>
                <p>We are a DIGITAL growth agency</p>
                <ul>
                    <li><i class="fa fa-check"></i>&nbsp;Tailored solutions</li>
                    <li><i class="fa fa-check"></i>&nbsp;Tell your story</li>
                    <li><i class="fa fa-check"></i>&nbsp;We are an extension of your team</li>
                </ul>
                </div>
            </section>

        </article>
        <button className="aboutusLeadsBtn">Ready to get started ?</button>
    </section>
    <SectionTen/>
    </>
)
export default AboutUs